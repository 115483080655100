import React from 'react';
import Icon from '../Utils/Icon/Icon';
import IconType from '../Utils/Icon/IconType';
import "./Header.css"

interface HeaderProps {
  userName: string,
}


function Header(props: HeaderProps): JSX.Element {
  return (
    <nav className="header">
      <ul>
        <li><a href="#" className="secondary">
          <Icon iconName={IconType.BARS} />
        </a></li>
      </ul>
      <ul>
        <li><a href="/dashboard/" className="secondary"><strong>Tuglife</strong></a></li>
      </ul>
      <ul>
        <li><a href="/dashboard/user" className="secondary">
          <Icon iconName={IconType.USER} /> {props.userName}
        </a></li>
      </ul>
    </nav>
  )
};

export default Header;
