// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore, doc, setDoc, connectFirestoreEmulator } from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signOut
} from "firebase/auth";
import { company, firebaseConfig } from "./constants";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// Initialize Firebase

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);



const auth = getAuth();

const db = getFirestore(app);
const functions = getFunctions(app);

///////////////////////use local functions instance/////////////////
// connectFunctionsEmulator(functions, "localhost", 5001);
// connectFirestoreEmulator(db, 'localhost', 8080);
////////////////////////////////////////////////////////////////////


const provider = new GoogleAuthProvider();

const signInWithCredentials = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    console.log('creating user with', name, email, password);
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    // const userRef = doc(db, 'users');
    // TODO this should be done on the BE
    const dbref = doc(db, "companies", getCompanyForEmail(email)); //this ref makes sure we only use the data of the specific company
    await setDoc(doc(dbref, "users", user.uid), {
      name,
      "uid":user.uid
    });

  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const doSendPasswordResetEmail = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};


// todo find a way to lose this in the FE
 function getCompanyForEmail(email){
  if (email === undefined) {
    return undefined;
  }
  const splitMail = email.split("@");
  const domain = splitMail[splitMail.length-1];
  const domainCompanyMap= new Map([
    ["tuglife.nl", "tuglife"],
    ["tuglifepro.nl", "tuglifepro"],
  ]);
  return domainCompanyMap.get(domain);
}

export {
  auth,
  db,
  functions,
  signInWithCredentials,
  registerWithEmailAndPassword,
  doSendPasswordResetEmail,
  logout,
};