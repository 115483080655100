import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import NameId from "../../Models/NameId";
import User from "../../Models/User";
import "./VesselUserpage.css";
import { useQuery } from "../../Utils/CustomHooks";
import { mapDocToNameId } from "../../Utils/Mappers";
import { isIdInNameIdArray } from "../../Utils/NameIdHelpers";
import "./VesselUserpage.css";
import { useGlobalState } from "../../state";

interface VesselUserPageProps {}

function VesselUserPage(props: VesselUserPageProps): JSX.Element {
  const [vesselNameIds, setVesselNameIds] = useState<NameId[]>([]);
  const [company, setCompany] = useGlobalState("company");

  const [selectedUser, setSelectedUser] = useState<User>();

  let paramQuery = useQuery();
  var qpUser = paramQuery.get("user");

  useEffect(() => {
    if (company) {
      getVesselData();
      // getUserData();
      if (qpUser !== null) {
        getUserById(qpUser);
      }
    }
  }, [company]);

  //get all vessels from database
  async function getVesselData() {
    const docRef = collection(db, "companies", company, "vessels"); //this ref makes sure we only use the data of the specific company
    const querySnapshot = await getDocs(docRef);
    const vesselData: NameId[] = [];
    querySnapshot.forEach((doc) => {
      vesselData.push(mapDocToNameId(doc));
    });
    setVesselNameIds(vesselData.filter((vessel) => vessel.name !== undefined));
  }

  async function getUserById(userId: string) {
    const docRef = doc(db, "companies", company, "users", userId);
    await getDoc(docRef).then((data) => {
      setSelectedUser(data.data() as User);
      // console.log("userEditRights",(data.data() as User).hasEditRights)
    });
  }

  async function updateVesselRights(user: User, vesselNameId: NameId) {
    const userId = user.uid;
    const userRef = doc(db, "companies", company, "users", userId);
    // setSubmitLoading(true);

    //add or remove?
    if (isIdInNameIdArray(vesselNameId.id, user.hasEditRights)) {
      //remove
      await updateDoc(userRef, {
        hasEditRights: arrayRemove(vesselNameId),
      }).then((result) => {
        getUserById(userId);
      });
    } else {
      //add
      await updateDoc(userRef, {
        hasEditRights: arrayUnion(vesselNameId),
      }).then((result) => {
        getUserById(userId);
      });
    }
  }

  return (
    <div>
      <h1>VesselUserPage</h1>
      <p>1 - Data for user {selectedUser?.name}</p>
      {selectedUser ? (
        <form>
          {selectedUser && (
            <>
              <ul className="list">
                <table>
                  <thead>
                    <tr>
                      <th className="first-column">Name</th>
                      <th>Edit Rights</th>
                    </tr>
                    {vesselNameIds.map((vessel) => (
                      <tr>
                        <td>
                          <p>{vessel.name}</p>
                        </td>
                        <td>
                          <input
                            className="checkbox-style"
                            type="checkbox"
                            id={"CB-" + vessel.name}
                            key={"CB-" + vessel.name}
                            name={"CB-" + vessel.name}
                            defaultChecked={isIdInNameIdArray(
                              vessel.id,
                              selectedUser.hasEditRights
                            )}
                            onChange={() =>
                              updateVesselRights(selectedUser, vessel)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </thead>
                </table>
              </ul>
            </>
          )}
        </form>
      ) : (
        <p aria-busy="true">Retrieving data, please wait, please wait…</p>
      )}
    </div>
  );
}

export default VesselUserPage;
