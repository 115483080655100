import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { auth, doSendPasswordResetEmail } from "../firebase";
import "./Login.css";

function Reset() {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {
    if (loading) return;
    if (user) history.replace("/dashboard");
  }, [user, loading]);

  return (
    <main class="container login">
      <article class="grid">
        <div>
          <hgroup>
            <h1>Register</h1>
          </hgroup>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />
          <button
            onClick={() => doSendPasswordResetEmail(email)}
          >
            Send password reset email
          </button>

          <div>
            Don't have an account? <Link to="/register">Register</Link> now.
          </div>
        </div>
      </article>
    </main>
  );
}

export default Reset;
