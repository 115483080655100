
interface BlockedUserPageProps {
}


function BlockedUserPage(props: BlockedUserPageProps): JSX.Element {
  return (
    <div >
      <h1>No access</h1>
      <p>
        The account used is currently blocked or waiting to get access. This happens in two cases:
        <ul>
          <li><strong>New accounts</strong> are blocked until the company Tuglife Administrator has given the account access</li>
          <li><strong>Existing accounts</strong> can be blocked by the administrator</li>
        </ul>
        Please reach out to your administrator.
      </p>
    </div>)
};

export default BlockedUserPage;