import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { getDocs, collection } from "firebase/firestore";
import VesselListItem from "./VesselListItem";
import Vessel from "../../Models/Vessel";
import { useGlobalState } from "../../state";

function VesselsPage() {
  const [vessels, setVessels] = useState<Vessel[]>([]);
  const [company, setCompany] = useGlobalState("company");


  useEffect(() => {
    if(company){
    getVesselData();
    }
  }, [company]);

  async function getVesselData() {
    const docRef = collection(db, "companies", company, "vessels"); //this ref makes sure we only use the data of the specific company
    const querySnapshot = await getDocs(docRef);
    const vesselData: Vessel[] = [];
    querySnapshot.forEach((doc) => {
      vesselData.push(doc.data() as Vessel)
    });
    setVessels(vesselData);
  }

  return (
    <div>
      <h1>Vessels</h1>
      <p>The vessels in your company {company} are:</p>
      <ul className="list">
        {vessels.map(vessel => (
          <VesselListItem vessel={vessel} />
        ))}
      </ul>
    </div>
  )
};

export default VesselsPage;
