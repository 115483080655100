import NoticeType from "../Models/Notices";
import ContractType from '../Models/ContractType';
import NameId from '../Models/NameId';

export function getTextForNoticeType(noticeType: NoticeType): string {
  switch (noticeType) {
    case NoticeType.BUNKER_NOTICE:
      return "Bunker Notice";
    case NoticeType.STATUS_REPORT:
      return "Status Report";
    case NoticeType.DEPARTURE_REPORT:
      return "Departure Report";
    case NoticeType.ARRIVAL_NOTICE:
      return "Arrival Notice";
    case NoticeType.ONHIRE_REPORT:
      return "Onhire Report";
    case NoticeType.OFFHIRE_REPORT:
      return "Offhire Report";
    case NoticeType.NOTICE_OF_READINESS:
      return "Notice of Readiness";
    case NoticeType.NOTICE_OF_DEPARTURE:
      return "Notice of Departure";
    case NoticeType.NOTICE_OF_ARRIVAL:
      return "Notice of Arrival";
    case NoticeType.NOTICE_OF_REDELIVERY:
      return "Notice of Redelivery";
  }
}

export function getTextForContractType(contractType: ContractType): string {
  switch(contractType) {
    case ContractType.SUPPLYTIME2017: return "SUPPLYTIME 2017";
    case ContractType.TOWCON2008: return "TOWCON 2008";
  }
}

export function getVesselsString(vesselnames:NameId[] | undefined){
  if(vesselnames === undefined || vesselnames.length == 0) {
    return "No vessel rights"
  }
  let retval = "";
  vesselnames.forEach((vesselname,index)=> {
    if(index < 3) {
      retval = retval + vesselname.name +", ";
    }else if (index == 3){
      retval = retval + "..."
    }

  });
  return retval;
}
