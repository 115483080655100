import { Timestamp } from "firebase/firestore";

export function getTodayDateString(){
  return new Date().toISOString().substr(0, 10);
}

export function getNowTimeString(){
  return new Date().toLocaleTimeString();
}

export function formatTimestamp(date: Timestamp){
  return date.toDate().toISOString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
}

export function formatTimestampToDate(date: Timestamp){
  return date.toDate().toISOString().slice(0, 10).replace(/-/g, "/").replace("T", " ");
}

export function formatTimestampToTime(date: Timestamp){
  return date.toDate().toISOString().slice(11, 19).replace(/-/g, "/").replace("T", " ");
}