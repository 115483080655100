import { FieldDefinition } from "../Models/FieldDefinition";

export const bunkerFields = [
  { name: "IFO380", id: "ifo380" },
  { name: "IFO 380 0.5%S", id: "ifo38005" },
  { name: "ULSFO 0.1%S", id: "ulsfo" },
  { name: "MGO (DMA) 0.5%S", id: "mgo" },
  { name: "LSMGO 0.1%S", id: "lsmgo" },
  { name: "MDO", id: "mdo" },
  { name: "Lubrication Oil", id: "luboil" },
  { name: "Fresh Water", id: "freshwater" }
] as FieldDefinition[];
