import { useState } from "react";

interface UTCOffsetFromFieldProps {
  label:string;
}

function UTCOffsetFromField(props: UTCOffsetFromFieldProps): JSX.Element {
  const [time, setTime] = useState("");

  return (
    <>
      <label htmlFor="UTCOffset">{props.label}
      
        <div>
          <select id="UTCOffset" name="UTCOffset" 
          onChange={((e)=>{setTime(e.target.value)})}  value={time}  required>
            <option value="-12.00"> UTC -12:00</option>
            <option value="-11.00"> UTC -11:00</option>
            <option value="-10.00"> UTC -10:00</option>
            <option value="-9.50"> UTC -09:30</option>
            <option value="-9.00"> UTC -09:00</option>
            <option value="-8.00"> UTC -08:00</option>
            <option value="-7.00"> UTC -07:00</option>
            <option value="-6.00"> UTC -06:00</option>
            <option value="-5.00"> UTC -05:00</option>
            <option value="-4.00"> UTC -04:00</option>
            <option value="-3.50"> UTC -03:30</option>
            <option value="-3.00"> UTC -03:00</option>
            <option value="-2.50"> UTC -02:30</option>
            <option value="-2.00"> UTC -02:00</option>
            <option value="-1.00"> UTC -01:00</option>
            <option value="0.00" >UTC ±00:00</option>
            <option value="1.00">UTC +01:00</option>
            <option value="2.00">UTC +02:00</option>
            <option value="3.00">UTC +03:00</option>
            <option value="3.50">UTC +03:30</option>
            <option value="4.00">UTC +04:00</option>
            <option value="4.50">UTC +04:30</option>
            <option value="5.00">UTC +05:00</option>
            <option value="5.50">UTC +05:30</option>
            <option value="6.00">UTC +06:00</option>
            <option value="6.50">UTC +06:30</option>
            <option value="7.00">UTC +07:00</option>
            <option value="8.00">UTC +08:00</option>
            <option value="8.75">UTC +08:45</option>
            <option value="9.00">UTC +09:00</option>
            <option value="9.50">UTC +09:30</option>
            <option value="10.00">UTC +10:00</option>
            <option value="10.50">UTC +10:30</option>
            <option value="11.00">UTC +11:00</option>
            <option value="12.00">UTC +12:00</option>
            <option value="12.75">UTC +12:45</option>
            <option value="13.00">UTC +13:00</option>
            <option value="13.75">UTC +13:45</option>
            <option value="14.00">UTC +14:00</option>
            
          </select>

        </div>
      </label>

    </>
  )

}

export default UTCOffsetFromField;