import React from 'react';
import Vessel from '../../Models/Vessel';

interface VesselListItemProps {
  vessel: Vessel,
}

function VesselListItem(props: VesselListItemProps): JSX.Element {
  const vessel = props.vessel

  function onVesselSelect(vessel: Vessel) {
    window.alert("clicked on " + vessel.name)
  }

  return (
    <li className="list-row">
      <article className="article-list-row"
        onClick={() => onVesselSelect(vessel)}>
        <h2>{vessel.name}</h2>
        <p>{vessel.vesselType}</p>
      </article>
    </li>
  )
};

export default VesselListItem;
