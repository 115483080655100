import Vessel from "../../Models/Vessel";


interface VesselProps {
    vessel: Vessel | undefined
}

function AddVesselComponent(props: VesselProps): JSX.Element {

  const vessel = props.vessel

  return (
    <div>
    <li className="list-row">
      {vessel &&
      <article className="article-list-row">
         <ul>
          <li>Vessel Name : {vessel.name}</li>
          <li>Vessel Type : {vessel.vesselType}</li>  
          <li>Notes : {vessel.notes}</li>
          
          </ul>
        </article>
        }
    </li>
  </div>
  )
};

export default AddVesselComponent;