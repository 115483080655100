  // eslint-disable-next-line
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import Project from '../Models/Project';
import Vessel from '../Models/Vessel';
import ContractType from '../Models/ContractType';
import NameId from '../Models/NameId';
  
export function mapDocToProject(doc: QueryDocumentSnapshot<DocumentData>): Project {
const contractType =  doc.data().contractType.toUpperCase() as ContractType;
  return {
    projectName: doc.data().projectName,
    projectReference: doc.data().projectReference,
    vesselName:doc.data().vesselName,
    contractType,
    id: doc.id,
    //todo add more fields
  } as Project;
}

export function mapDocToVessel(doc: QueryDocumentSnapshot<DocumentData>): Vessel {
  return {
    name: doc.data().name,
    id: doc.id,
  } as Vessel;
}

export function mapDocToNameId(doc: QueryDocumentSnapshot<DocumentData>): NameId {
  return {
    name: doc.data().name,
    id: doc.id,
  } as NameId;
}



export function mapVesselToNameId(vessel: Vessel){
  return {
    name: vessel.name,
    id: vessel.id,
  } as NameId;}
  