import React, {ReactNode, createContext, useReducer} from "react";
import Reducer from './Reducer'
import User from "../Models/User";

export interface StoreInterface {
  user: User | null;
  error: string | null;
}

interface Props {
  children?: ReactNode
}

const initialState = {
    user: null,
    error: null
};

const Store = ({children}:Props) => {

  
  const persistedState = getSavedState();
    const [state, dispatch] = useReducer(Reducer, persistedState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};


function getSavedState() : StoreInterface {
  const locStore = sessionStorage.getItem('localSavedState');
  if(locStore){
    return JSON.parse(locStore) as StoreInterface;
  }
  return initialState;
}


export const Context = createContext<StoreInterface | any[]>(initialState);
export default Store;