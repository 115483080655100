import ContractType from "../Models/ContractType";
import NoticeType from "../Models/Notices";

export function getNoticeTypesForContractType(contractType: ContractType | undefined) {
  if(contractType === undefined){
    return [NoticeType.STATUS_REPORT]
  }
  if (contractType.toString() ===  "SUPPLYTIME2017") {
    return [NoticeType.ARRIVAL_NOTICE, NoticeType.DEPARTURE_REPORT, NoticeType.ONHIRE_REPORT, NoticeType.OFFHIRE_REPORT, NoticeType.STATUS_REPORT, NoticeType.BUNKER_NOTICE];
  } else if (contractType.toString() === "TOWCON2008") {
    return [NoticeType.NOTICE_OF_ARRIVAL, NoticeType.NOTICE_OF_DEPARTURE, NoticeType.NOTICE_OF_READINESS, NoticeType.NOTICE_OF_REDELIVERY, NoticeType.STATUS_REPORT, NoticeType.BUNKER_NOTICE];
  }
  return [];

}
