import React from "react";
import Project from "../Models/Project";
import ProjectMeta from '../Models/ProjectMeta';
import { timeRangeToString } from "../Utils/TimeRangeHelpers";
interface ProjectListItemProps {
  project: Project;
  projectMeta?: ProjectMeta;
}

function ProjectCard(props: ProjectListItemProps): JSX.Element {
  const project = props.project;
  const projectMeta = props.projectMeta;

  function onProjectSelect(project: Project) {
    const url = "/dashboard/project?reference=" + project.projectReference;
    if (window.location.href.indexOf(url) > -1) {
    } else {
      window.location.href = url;
    }
  }

  return (
    <div>
        <article
          className="article-list-row"
          onClick={() => onProjectSelect(project)}
        >
          <ul>
            <li><b>Projectname:</b> {project.projectName}</li>
            <li><b>Project reference:</b> {project.projectReference}</li>
            <li><b>Vessel name:</b> {project.vesselName}</li>
            { projectMeta && 
              <>
                <li><b>Project status:</b> {projectMeta.projectStatus}</li> 
                <li><b>Project length:</b> {timeRangeToString( projectMeta.projectLength)}</li> 
              </>
            }
          </ul>
        </article>
        <br></br>
    </div>
  );
}

export default ProjectCard;
