import '@picocss/pico'

import "./App.css";
import "./list.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./Login/Login";
import Register from "./Login/Register";
import Reset from "./Login/Reset";
import Main from "./Frame/Main";
import Store from './State/Store';


function App() {
  return (
    <div className="app">
      <Store>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/reset" component={Reset} />
            <Route path="/dashboard" component={Main} />
          </Switch>
        </Router>
      </Store>
    </div>
  );
}
export default App;