import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { getDocs, collection } from "firebase/firestore";
import User from "../../../Models/User";
import Modal from "../../../Components/Modal";
import UserAdminModalContent from "./UserAdminModalContent";
import { useQuery } from "../../../Utils/CustomHooks";
import { useGlobalState } from "../../../state";

function UserAdministrationPage() {
  const [users, setUsers] = useState<User[]>([]);
  const [modalOpenId, setModalOpenId] = useState("");
  const [company, setCompany] = useGlobalState("company");

  let paramQuery = useQuery();
  var urlOpenUser = paramQuery.get("modal");

  useEffect(() => {
    if (company) {
      getUserData(true);
    }
  }, [company]);

  async function getUserData(firstPageLoad: boolean) {
    const docRef = collection(db, "companies", company, "users");
    const querySnapshot = await getDocs(docRef);
    const userData: User[] = [];
    querySnapshot.forEach((doc) => {
      userData.push(doc.data() as User);
    });
    setUsers(userData);
    if (firstPageLoad && urlOpenUser) {
      openModalByUrlQuery(urlOpenUser!, userData);
    }
  }

  const openModalByUrlQuery = (modalId: string, users: User[]) => {
    const modalIds: string[] = [];
    users.forEach((user) => {
      modalIds.push(getModalId(user));
    });
    if (modalIds.includes(modalId)) {
      setModalOpenId(modalId);
      toggleModal(modalId);
    }
  };

  const toggleModal = (modalId: string) => {
    if (modalOpenId === modalId) {
      //close modal
      setModalOpenId("");
      getUserData(false); //refresh page content after modal close
    } else {
      //open modal
      setModalOpenId(modalId);
    }
  };

  const getModalId = (user: User) => {
    if(user && user.uid){
    const hash = user.uid
      .split("")
      .reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0)
      .toString();
    return "m" + hash;
    }else{
      console.log("no uid for ", user)
      return "m-error";

    }
  };

  return (
    <div>
      <h1>Users</h1>
      <p>The users in your company {company} are:</p>
      <ul className="list">
        {users.map((user) => (
          <li className="list-row">
            <article className="article-list-row">
              <p>
                User: {user.name} <br />
                Status: {user.accountStatus} <br />
                UserType: {user.userType}
              </p>
              <button
                data-target="modal-example"
                onClick={() => toggleModal(getModalId(user))}
              >
                Edit user {user.name}
              </button>
              <Modal
                modalId={getModalId(user)}
                title={"Edit user " + user.name}
                content={<UserAdminModalContent user={user} />}
                isOpen={getModalId(user) === modalOpenId}
                toggleModal={toggleModal}
              />
            </article>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default UserAdministrationPage;
