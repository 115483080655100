import { useState } from "react";
import { getNowTimeString } from '../Utils/DateHelpers';

// data on form submit should be retrieved by form component 
//     const timestring = event.target.date.value+ " "+ event.target.time.value;


interface TimeFormFieldProps {
  label:string;
}

function TimeFormField(props: TimeFormFieldProps): JSX.Element {
  const [time, setTime] = useState("");

  return (
    <>
      <label htmlFor="time">{props.label}
      <div className="flex-container ">
        <input type="time" id="time" name="time" className="flex-item input-text" onChange={((e)=>{setTime(e.target.value)})}  value={time}  required />
        <button type="button" className="button-today" onClick={()=>{setTime(getNowTimeString())}}>now</button>   
        </div>
      </label>

    </>
  )

}

export default TimeFormField;