import { formatTimestampToDate, formatTimestampToTime } from "../../Utils/DateHelpers";
import Event from "../../Models/Event";


interface EventsTableProps {
  events: Event[],
}

function EventsTable(props: EventsTableProps): JSX.Element {
  const events = props.events;

  return (

    <table>
    <thead>
      <tr>
        <th scope="col">Date</th>
        <th scope="col">Time</th>
        <th scope="col">Type</th>
        <th scope="col">Notes</th>
      </tr>
    </thead>
    <tbody>
      {events.map((e) => (
        <tr>
          <td>{formatTimestampToDate(e.eventTimestamp)}</td>
          <td>{formatTimestampToTime(e.eventTimestamp)}</td>
          <td>{e.noticeType}</td>
          <td>...</td>
        </tr>
      ))}
    </tbody>
  </table>
  );
}

export default EventsTable;