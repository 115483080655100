/* eslint-disable jsx-a11y/anchor-has-content */


interface ModalProps {
  modalId: string;
  title: string;
  // text: string; //replace by component?
  content?: React.ReactNode;
  isOpen:boolean;
  toggleModal:(modalId:string)=>void;
}



function Modal(props: ModalProps): JSX.Element {


  return (
    <dialog id={props.modalId} open={props.isOpen} >
      <article>
        <a
          href="#"
          aria-label="Close"
          className="close"
          data-target={props.modalId}
          onClick={() => props.toggleModal(props.modalId)}
          ></a>
        <h3>{props.title}</h3>
       {props.content}
      </article>
    </dialog>
  );
}

export default Modal;
