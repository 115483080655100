import { bunkerFields } from "../../Utils/FieldConstants";

interface InventoryComponentProps {}
//todo could be nice to be able to call this component in order to get the data

function InventoryComponent(props: InventoryComponentProps): JSX.Element {

  return (
    <div>
      <br />
      <h3>Inventory data</h3>
      <table>
        <tr>
          <th>Product</th>
          <th>
            Quantities
          </th>
        </tr>
        {bunkerFields.map(field => (
          <tr>
            <td>{field.name}</td>
            <td>
              <label htmlFor="ifo380">
                {field.name}
                <input className="input-text" type="number" id={field.id} name={field.id} step="0.1" />
              </label>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}

export default InventoryComponent;
