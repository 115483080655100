import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import DateFormField from "../../Components/DateFormField";
import TimeFormField from "../../Components/TimeFormField";
import UTCOffsetFromField from "../../Components/UTCOffsetFromField";
import { auth, db } from "../../firebase";
import NoticeType from "../../Models/Notices";
import Project from "../../Models/Project";
import { useGlobalState } from "../../state";
import { mapDocToProject } from "../../Utils/Mappers";
import BunkerComponent from "./BunkerComponent";
import InventoryComponent from "./InventoryComponent";
import { Inventory } from "../../Models/Inventory";
import { getTextForNoticeType } from "../../Utils/TextsHelpers";
import { getNoticeTypesForContractType } from "../../Utils/NoticeTypeHelper";
import {
  getEventTimestampUTC,
  getEventTimestamp,
  getTimeStringUTC,
} from "../../Utils/UTCHelpers";
import NameId from "../../Models/NameId";
import { Context } from "../../State/Store";
import { getUserFromState } from "../../State/StateHelper";

interface AddEventsPageProps {}

function AddEventsPage(props: AddEventsPageProps): JSX.Element {
  const [vessels, setVessels] = useState<NameId[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [noticeTypes, setNoticeTypes] = useState<NoticeType[]>([]);
  //todo base noticetypes on logic
  const [hasRetrievedData, setHasRetrievedData] = useState(false);

  // @ts-ignore
  const [state, dispatch] = useContext(Context);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [authUser, loading, error] = useAuthState(auth);
  const [globalname, setGlobalname] = useGlobalState("username");
  const [company, setCompany] = useGlobalState("company");

  const [selectedVessel, setSelectedVessel] = useState<NameId>();
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [selectedNotice, setSelectedNotice] = useState<NoticeType>();

  const functions = getFunctions();

  useEffect(() => {
    const user = getUserFromState(state);

    if (user && user.hasEditRights) {
      setVessels(user.hasEditRights);
    }
  }, [state]); //user

  function updateSelectedVessel(vessel: NameId) {
    setSelectedVessel(vessel);
    if (vessel === undefined) {
      return;
    }
    //todo fix project update
    getProjects(vessel.name);
  }

  function updateSelectedNotice(notice: NoticeType) {
    setSelectedNotice(notice);
  }

  async function getProjects(vesselName: string) {
    const docRef = collection(db, "companies", company, "projects");
    const q = query(docRef, where("vesselName", "==", vesselName));
    const querySnapshot = await getDocs(q);
    var tempProjects: Project[] = [];
    querySnapshot.forEach((doc) => {
      tempProjects.push(mapDocToProject(doc));
    });
    tempProjects = tempProjects.sort((a: Project, b: Project) =>
      a.projectReference < b.projectReference ? -1 : 1
    );
    setProjects(tempProjects);
  }

  async function handleSubmit(event: any) {
    setSubmitLoading(true);
    event.preventDefault();

    const postdata = createPostData(event);
    console.log("postdata:", postdata);

    try {
      const dbrefEvent = collection(db, "companies", company, "events");
      await addDoc(dbrefEvent, postdata).then((result) => {
        setSubmitLoading(false);
        window.location.href = "/dashboard/event-confirm?event=" + result.id;
      });
    } catch (err) {
      console.error(err);
      setSubmitLoading(false);
    }
  }

  function createPostData(event: any): any {
    const timestring = event.target.date.value + " " + event.target.time.value;

    // calculate for machine time
    var MachineOffset = new Date();
    var MachineOffsetFullInMinutes = MachineOffset.getTimezoneOffset();

    console.log("UTCOffset", event.target.UTCOffset.value);
    console.log("time", event.target.time.value);
    console.log("date", event.target.date.value);

    const aVessel = vessels[parseInt(event.target.vessel.value)];
    const aProject = projects[parseInt(event.target.project.value)];
    const inventory = getInventoryFields(event);

    const postdata = {
      vesselName: aVessel.name,
      vesselId: aVessel.id,
      projectName: aProject.projectName,
      projectId: aProject.id,
      projectReference: aProject.projectReference,
      UTCOffset: event.target.UTCOffset.value, //UTC offset as filled in on form
      eventTimestamp: getEventTimestamp(event, MachineOffsetFullInMinutes), //
      eventTimestampUTC: getEventTimestampUTC(
        event,
        MachineOffsetFullInMinutes
      ), // showing timestamp of the event corrected for UTC offset as filled in
      createdTimestamp: serverTimestamp(), //showing timestamp of creation defined by server-> UTC
      noticeType: noticeTypes[event.target.notice.value].valueOf(),
      createdUserid: authUser?.uid,
      createdUserName: globalname,
      location: event.target.location.value,
      statusReportReason: getStatusReportReason(event),
      notes: event.target.notes.value,
      inventory,
      MachineOffsetFullMin: MachineOffsetFullInMinutes, //UTC offset in minutes based on MACHINE time
      timeStringLocal: timestring, // as it was filled in in local time
      timeStringUTC: getTimeStringUTC(
        getEventTimestampUTC(event, MachineOffsetFullInMinutes)
      ),
    };

    const postdataWithBunkers = {
      ...postdata,
      ...(selectedNotice === NoticeType.BUNKER_NOTICE && {
        bunkers: getAddedBunkerFields(event),
      }),
    };

    return postdataWithBunkers;
  }

  function getStatusReportReason(event: any): string {
    const statusReportReason = event.target.statusReportReason?.value;
    if (statusReportReason === undefined) {
      return "";
    }
    return statusReportReason;
  }

  function getInventoryFields(event: any): Inventory {
    return {
      ifo380: event.target.ifo380.value,
      ifo38005: event.target.ifo38005.value,
      ulsfo: event.target.ulsfo.value,
      mgo: event.target.mgo.value,
      lsmgo: event.target.lsmgo.value,
      mdo: event.target.mdo.value,
      luboil: event.target.luboil.value,
      freshwater: event.target.freshwater.value,
    };
  }

  function getAddedBunkerFields(event: any): Inventory {
    return {
      ifo380: event.target.add_ifo380.value,
      ifo38005: event.target.add_ifo38005.value,
      ulsfo: event.target.add_ulsfo.value,
      mgo: event.target.add_mgo.value,
      lsmgo: event.target.add_lsmgo.value,
      mdo: event.target.add_mdo.value,
      luboil: event.target.add_luboil.value,
      freshwater: event.target.add_freshwater.value,
    };
  }

  return (
    <div>
      <h1>AddEvents</h1>
      <p>Add an event, als je een vent bent.</p>
      {vessels.length > 0 ? ( //todo fix empty state for no user rights
        <form onSubmit={handleSubmit}>
          <label htmlFor="vessel">Vessel</label>
          <select
            id="vessel"
            required
            onChange={(e) =>
              updateSelectedVessel(vessels[parseInt(e.target.value)])
            }
          >
            <option value="" selected>
              Select a vessel
            </option>
            {vessels.map((vessel, index) => (
              <option value={index} key={index}>
                {vessel.name}
              </option>
            ))}
          </select>

          {selectedVessel && (
            <>
              <label htmlFor="project">Project</label>
              <select
                id="project"
                required
                onChange={(e: any) =>
                  setNoticeTypes(
                    getNoticeTypesForContractType(
                      projects[parseInt(e.target.value)].contractType
                    )
                  )
                }
              >
                <option value="" selected>
                  Select a project
                </option>
                {projects.map((project, index) => (
                  <option value={index} key={index}>
                    {project.projectReference} - {project.projectName}
                  </option>
                ))}
              </select>

              <label htmlFor="notice">Type of notice</label>
              <select
                id="notice"
                required
                onChange={(e) =>
                  updateSelectedNotice(noticeTypes[parseInt(e.target.value)])
                }
              >
                <option value="" selected>
                  Select a notice
                </option>
                {noticeTypes.map((noticeType, index) => (
                  <option value={index} key={index}>
                    {getTextForNoticeType(noticeType)}
                  </option>
                ))}
              </select>

              <p></p>
              <p>Current time in UTC : {new Date().toUTCString()}</p>

              <UTCOffsetFromField label="Current timezone offset to UTC" />
              <DateFormField label="Date" />
              <TimeFormField label="Time" />

              <label htmlFor="location">
                Location
                <textarea rows={1} id="location" name="location" />
              </label>

              {selectedNotice !== undefined && (
                <>
                  {selectedNotice === NoticeType.STATUS_REPORT && (
                    <label htmlFor="statusReportReason">
                      Reason for Status Report <i>(internal)</i>
                      <textarea
                        rows={1}
                        id="statusReportReason"
                        name="statusReportReason"
                      />
                    </label>
                  )}
                </>
              )}

              <br></br>
              <label htmlFor="notes">
                <h3>Notes </h3>
                <i>(shared with client)</i>
                <textarea rows={3} id="notes" name="notes" />
              </label>

              {selectedNotice !== undefined && (
                <>
                  {selectedNotice !== NoticeType.BUNKER_NOTICE ? (
                    <InventoryComponent /> //check notice if bunker notice
                  ) : (
                    <BunkerComponent />
                  )}
                </>
              )}

              {submitLoading ? (
                <input
                  type="submit"
                  aria-busy="true"
                  value="Saving event"
                  disabled
                />
              ) : (
                <input type="submit" value="Add event" />
              )}
            </>
          )}
        </form>
      ) : hasRetrievedData ? (
        <p>
          You don't have userrights to add events to a vessel. If this is
          incorrect, contact your administrator.
        </p>
      ) : (
        <p aria-busy="true">Retrieving data, please wait, please wait…</p>
      )}
    </div>
  );
}

export default AddEventsPage;
