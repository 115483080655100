const Reducer = (state:any, action:any) => {
  console.log("reducer",action)
  var updatedState;
  switch (action.type) {
      case 'SET_USER':
          updatedState =  {
              ...state,
              user: action.payload
          };
          break;
      case 'SET_ERROR':
          updatedState = {
              ...state,
              error: action.payload
          };
          break;
      default:
          updatedState = state;
          break;
  }
  sessionStorage.setItem('localSavedState', JSON.stringify(updatedState))
  return updatedState;

};

export default Reducer;