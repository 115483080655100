import { useQuery } from "../../Utils/CustomHooks";
import { doc } from 'firebase/firestore';
import { getDoc } from 'firebase/firestore';
import { db } from "../../firebase";
import Vessel from '../../Models/Vessel';
import { useState } from 'react';
import { useEffect } from 'react';
import AddVesselComponent from "./AddVesselComponent";
import "../../Frame/ConfirmPage.css"
import { Link } from 'react-router-dom';
import { useGlobalState } from "../../state";
//import VesselType from '../../Models/VesselType';

interface AddVesselConfirmPageProps {

}

function AddVesselConfirmPage(props: AddVesselConfirmPageProps): JSX.Element {
  let paramQuery = useQuery();
  var vesselId = paramQuery.get("vessel-id"); 
  const [vessel, setVessel] = useState<Vessel>();
  const [company, setCompany] = useGlobalState("company");


  useEffect(() => {
    if(company){
    getVesselId(vesselId!);
    }
  }, [company]);


  async function getVesselId(vesselId: string) { //CHANGE
    const docRef = doc(db, "companies", company, "vessels", vesselId); //vessel of vessels
    const querySnapshot = await getDoc(docRef);
    setVessel(querySnapshot.data() as Vessel )
  
  };

  return (
    <div >
      <h1>New Vessel created</h1>
      <AddVesselComponent vessel={vessel} />
      <div className="grid cta-buttons">
        <Link to="/dashboard/add-vessel">
        <button type="button">Add another vessel</button>   
        </Link >  
        <button className="secondary">Go to vessel</button>        {/* //TODO implement button */}

      </div>
    </div>)
};

export default AddVesselConfirmPage;
