enum IconType {
  PLUS = "PLUS",
  SETTINGS = "SETTINGS",
  EDIT = "EDIT",
  LIST = "LIST",
  AT = "AT",
  PASSWORD = "PASSWORD",
  BARS = "BARS",
  USER = "USER"
}
export default IconType;
// export type IconTypes = IconType.PLUS | IconType.SETTINGS | IconType.EDIT | IconType.LIST | IconType.AT | IconType.PASSWORD | IconType.BARS;

