import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../firebase";
import User from "../../../Models/User";
import UserStatus from "../../../Models/AccountStatus";
import UserType from "../../../Models/UserType";
import NameId from "../../../Models/NameId";
import { getVesselsString } from "../../../Utils/TextsHelpers";
import { useGlobalState } from "../../../state";

interface UserAdminModalContentProps {
  user: User;
  // toggleModal:(modalId:string)=>void;
}

function UserAdminModalContent(props: UserAdminModalContentProps): JSX.Element {
  const [currentUser, setCurrentUser] = useState<User>();
  const [selectedUserType, setselectedUserType] = useState<UserType>();
  const [selectedUserStatus, setselectedUserStatus] = useState<UserStatus>();
  const [company, setCompany] = useGlobalState("company");

  // const [user, loading, error] = useAuthState(auth);
  // const [globalname, setGlobalname] = useGlobalState("username"); //needed?

  const userStatusses = Object.keys(UserStatus);
  const userTypes = Object.keys(UserType);

  useEffect(() => {
    if (company) {
      getUserById(props.user.uid);
    }
  }, [company]);

  async function getUserById(userId: string) {
    const docRef = doc(db, "companies", company, "users", userId);
    const querySnapshot = await getDoc(docRef);
    setCurrentUser(querySnapshot.data() as User);
  }

  async function updateAccountStatus() {
    const userId = props.user.uid;
    // setSubmitLoading(true);

    const postdata = { accountStatus: selectedUserStatus };

    if (selectedUserStatus) {
      try {
        const dbrefEvent = doc(db, "companies", company, "users", userId);
        await updateDoc(dbrefEvent, postdata).then((result) => {
          getUserById(props.user.uid);

          // setSubmitLoading(false);
        });
      } catch (err) {
        // setSubmitLoading(false);
      }
    }
  }

  async function updateUserType() {
    const userId = props.user.uid;
    // setSubmitLoading(true);

    const postdata = { userType: selectedUserType };

    if (selectedUserType) {
      try {
        const dbrefEvent = doc(db, "companies", company, "users", userId);
        await updateDoc(dbrefEvent, postdata).then((result) => {
          getUserById(props.user.uid);

          // setSubmitLoading(false);
        });
      } catch (err) {
        // setSubmitLoading(false);
      }
    }
  }

  return (
    <div>
      <p>
        Set User status
        <div>
          <select
            id="userStatusSelect"
            required
            onChange={(e) =>
              setselectedUserStatus(
                userStatusses[parseInt(e.target.value)] as UserStatus
              )
            }
          >
            {userStatusses.map((status, index) => (
              <option
                value={index}
                key={index}
                selected={status === props.user.accountStatus}
              >
                {status} {status === props.user.accountStatus && "(current)"}
              </option>
            ))}
          </select>

          {selectedUserStatus &&
            currentUser?.accountStatus !== selectedUserStatus && (
              <div>
                {"Update from " +
                  currentUser?.accountStatus +
                  " to " +
                  selectedUserStatus}
                <button
                  type="button"
                  onClick={() => {
                    updateAccountStatus();
                  }}
                >
                  Update status
                </button>
              </div>
            )}
        </div>
      </p>
      <p>
        Set User Type
        <div>
          <select
            id="userTypeSelect"
            required
            onChange={(e) =>
              setselectedUserType(
                userTypes[parseInt(e.target.value)] as UserType
              )
            }
          >
            {userTypes.map((userType, index) => (
              <option
                value={index}
                key={index}
                selected={userType === currentUser?.userType}
              >
                {userType} {userType === currentUser?.userType && "(current)"}
              </option>
            ))}
          </select>
          {selectedUserType &&
            currentUser &&
            currentUser.userType !== selectedUserType && (
              <div>
                {"Update from " +
                  currentUser.userType +
                  " to " +
                  selectedUserType}
                <button
                  type="button"
                  onClick={() => {
                    updateUserType();
                  }}
                >
                  Update type
                </button>
              </div>
            )}
        </div>
      </p>
      <p>
        Vessels:<br></br>
        {getVesselsString(currentUser?.hasEditRights)}
        <button
          type="button"
          onClick={() => {
            window.location.href =
              "/dashboard/vessel-user?user=" + props.user.uid;
          }}
        >
          Update vessel rights
        </button>
      </p>
    </div>
  );
}

export default UserAdminModalContent;
