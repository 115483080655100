import { useEffect, useState, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import { auth, db } from "../firebase";
// import { useGlobalState } from "../state";
import "./Main.css";
import "./Forms.css"

import AddVesselConfirmpage from "../Pages/AdminPage/AddVesselConfirmPage";
import AddVesselPage from "../Pages/AdminPage/AddVesselPage";
import AdminPage from "../Pages/AdminPage/AdminPage";
import VesselUserPage from "../Pages/AdminPage/VesselUserPage";
import Header from "./Header";
import Menu from "./Menu";
// import VesselUserConfirmPage from '../Pages/AdminPage/VesselUserConfirmPage';
import { Route, Switch } from "react-router-dom";
import BlockedUserPage from "../Pages/AccountPages/BlockedUserPage";
import UserPage from "../Pages/AccountPages/UserPage";
import AddEventsPage from "../Pages/AddEventsPage/AddEventPage";
import EventConfirmPage from "../Pages/AddEventsPage/EventConfirmPage";
import AddProjectPage from "../Pages/AddProjectPage/AddProjectPage";
import UserAdministrationPage from "../Pages/AdminPage/UserAdministration/UserAdministrationPage";
import DashboardPage from "../Pages/DashboardPage/DashboardPage";
import ProjectDetailPage from "../Pages/ProjectDetailPage/ProjectDetailPage";
import ProjectsPage from "../Pages/ProjectsPage/ProjectsPage";
import VesselsPage from "../Pages/VesselsPage/VesselsPage";
import User from "../Models/User";
import AccountStatus from "../Models/AccountStatus";
import UserType from "../Models/UserType";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Context } from "../State/Store";
import { getUserFromState } from "../State/StateHelper";
import { useGlobalState } from "../state";

function Main() {
  const [authUser, loading, error] = useAuthState(auth);
  const [globalName, setGlobalName] = useGlobalState("username");
  const [globalCompany, setGlobalCompany] = useGlobalState("company");


  // const [globaluser, setGlobalUser] = useGlobalState("user");


  const [isBlocked, setIsBlocked] = useState(false); //set to false to prevent page jumping
  const [isAdmin, setIsAdmin] = useState(false); //set to false to prevent page jumping
 
    // @ts-ignore
    const [state, dispatch] = useContext(Context);

  const history = useHistory();

  const functions = getFunctions();

// this function sets the global userdata which is used everywhere to get the company!
// todo implement caching to save data
  const fetchUserName = async () => {
      const getSelf = httpsCallable(functions, "getSelf");
      getSelf().then((result) => {
        const userdata = result.data as User;
        console.log("got self!", userdata)
        dispatch({type: 'SET_USER', payload: userdata});
   
        setIsBlocked(
          userdata.accountStatus === AccountStatus.BLOCKED ||
            userdata.accountStatus === AccountStatus.WAITING
        );
        setIsAdmin(userdata.userType === UserType.ADMIN);
        setGlobalName(userdata.name);
        setGlobalCompany(userdata.company);
        // setGlobalUser(userdata);
      }).catch((error) => {
        console.error(error);
        alert("An error occured while fetching user data");
      });
  }

  useEffect(() => {
    const user = getUserFromState(state);

    if (user && user.company) {
      setGlobalCompany(user.company)
      setGlobalName(user.name)
  
    }
    if (loading) return;
    //dont show the dashboard if no user
    if (!authUser) return history.replace("/");

    fetchUserName();
  }, [authUser, loading]);

  return (
    <div>
      <Header userName={globalName!} />
      <Menu isBlocked={isBlocked} isAdmin />
      <div className="main">
        {/* Set up component here */}
        {!isBlocked ? (
          <Switch>
            <Route exact path="/dashboard/" component={DashboardPage} />
            <Route exact path="/dashboard/admin" component={AdminPage} />
            <Route
              exact
              path="/dashboard/add-vessel"
              component={AddVesselPage}
            />
            <Route
              exact
              path="/dashboard/add-vessel-confirm"
              component={AddVesselConfirmpage}
            />
            <Route
              exact
              path="/dashboard/vessel-user"
              component={VesselUserPage}
            />
            <Route exact path="/dashboard/vessels" component={VesselsPage} />
            <Route exact path="/dashboard/projects" component={ProjectsPage} />
            <Route
              exact
              path="/dashboard/add-event"
              component={AddEventsPage}
            />
            <Route
              exact
              path="/dashboard/event-confirm"
              component={EventConfirmPage}
            />
            <Route
              exact
              path="/dashboard/add-project"
              component={AddProjectPage}
            />
            <Route
              exact
              path="/dashboard/project"
              component={ProjectDetailPage}
            />
            <Route
              exact
              path="/dashboard/user-administration"
              component={UserAdministrationPage}
            />
            <Route
              exact
              path="/dashboard/vessel-user"
              component={VesselUserPage}
            />
            <Route exact path="/dashboard/user" component={UserPage} />

            {/* <Route exact path="/dashboard/vessel-user-confirm" component={VesselUserConfirmPage} /> */}
          </Switch>
        ) : (
          <BlockedUserPage />
        )}
      </div>
    </div>
  );
}

export default Main;
