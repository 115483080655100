enum NoticeType {
  // generic
  STATUS_REPORT = "STATUS_REPORT",
  DEPARTURE_REPORT = "DEPARTURE_REPORT",
  ARRIVAL_NOTICE = "ARRIVAL_NOTICE",

  // hire (supplytime)
  ONHIRE_REPORT = "ONHIRE_REPORT",
  OFFHIRE_REPORT = "OFFHIRE_REPORT",

  // towcon
  NOTICE_OF_READINESS = "NOTICE_OF_READINESS",
  NOTICE_OF_DEPARTURE = "NOTICE_OF_DEPARTURE",
  NOTICE_OF_ARRIVAL = "NOTICE_OF_ARRIVAL",
  NOTICE_OF_REDELIVERY = "NOTICE_OF_REDELIVERY",
  
  //generic
  BUNKER_NOTICE = "BUNKER_NOTICE" // NV - ik denk dat deze wel hier bij hoort - omdat het wel impact heeft op je contract - maar het is niet echt een contract notice
  // NV - Dit zijn enkel de 'echte notice's' - i.e. Daily Report & maandelijkse rapporten (zitten er niet bij)
  // More specs van elke notice; https://docs.google.com/spreadsheets/d/127b9uYdV3eoUgeKr3qgAO2lIyEiCToD6ZHkHzsVdkyM/edit#gid=0 

}


export default NoticeType;
