import { useQuery } from "../../Utils/CustomHooks";
import { doc } from "firebase/firestore";
import { getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Event from "../../Models/Event";
import { useState } from "react";
import { useEffect } from "react";
import EventComponent from "./EventComponent";
import "../../Frame/ConfirmPage.css";
import { Link } from "react-router-dom";
import { useGlobalState } from "../../state";

interface EventConfirmPageProps {}

function EventConfirmPage(props: EventConfirmPageProps): JSX.Element {
  let paramQuery = useQuery();
  var eventId = paramQuery.get("event");
  const [event, setEvent] = useState<Event>();
  const [company, setCompany] = useGlobalState("company");

  useEffect(() => {
    if (company) {
      getEvent(eventId!);
    }
  }, []);

  async function getEvent(eventId: string) {
    const docRef = doc(db, "companies", company, "events", eventId);
    const querySnapshot = await getDoc(docRef);
    setEvent(querySnapshot.data() as Event);
  }

  return (
    <div>
      <h1>Event created</h1>
      <EventComponent event={event} />
      <div className="grid cta-buttons">
        <Link to="/dashboard/add-event">
          <button type="button">Add another event</button>
        </Link>
        <Link to={"/dashboard/project?projectId=" + event?.projectId}>
          <button className="secondary">Go to project</button>
        </Link>
      </div>
    </div>
  );
}

export default EventConfirmPage;
