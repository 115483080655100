import { bunkerFields } from '../../Utils/FieldConstants';

interface BunkerComponentProps {}

function BunkerComponent(props: BunkerComponentProps): JSX.Element {

  return (
    <>
      <h3>Bunker data</h3>
      <table>
        <tr>
          <th> Product </th>
          <th>
            Quantities <u>before</u> bunkering
          </th>
          <th>Total quantities Bunkered </th>
        </tr>
        {bunkerFields.map((bunkerField) => (
          <tr>
            <td>{bunkerField.name}</td>
            <td>
              {/* zou je dit niet willen pre-fillen met de laatste stand?  */}
              <label htmlFor={bunkerField.id}>
                <input type="number" className="input-text" step="0.1" id={bunkerField.id} name={bunkerField.id} />
              </label>
            </td>
            <td>
              <label htmlFor={"add_"+bunkerField.id}>
                <input type="number" className="input-text" step="0.1" id={"add_"+bunkerField.id} name={"add_"+bunkerField.id}  />
              </label>
            </td>
          </tr>
        ))}
      </table>
    </>
  );
}

export default BunkerComponent;
