import React, { useState } from "react";
import VesselType from "../../Models/VesselType";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useGlobalState } from "../../state";

function AddVesselPage() {
  const vesselTypes = Object.values(VesselType);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [company, setCompany] = useGlobalState("company");

  const [user, loading, error] = useAuthState(auth);
  const [globalname, setGlobalname] = useGlobalState("username");

  async function handleSubmit(event: any) {
    setSubmitLoading(true);
    event.preventDefault();
    if (company) {
      try {
        const dbrefEvent = collection(db, "companies", company, "vessels");
        await addDoc(dbrefEvent, {
          name: event.target.vesselName.value,
          vesselType:
            Object.keys(VesselType)[event.target.vesselType.value].valueOf(),
          createdTimestamp: serverTimestamp(),
          createdUserid: user?.uid,
          createdUserName: globalname,
          notes: event.target.notes.value,
        }).then((result) => {
          setSubmitLoading(false);
          // todo create vessel detail page
          window.location.href =
            "/dashboard/add-vessel-confirm?vessel-id=" + result.id;
        });
      } catch (err) {
        console.error(err);
        setSubmitLoading(false);
      }
    }
  }

  return (
    <div>
      <h1>Add Vessel</h1>
      <br />
      {company ? 

      (<form onSubmit={handleSubmit}>
        <label htmlFor="vesselName">
          Vessel Name
          <input id="vesselName" name="vesselName" className="input-text" />
        </label>

        <label htmlFor="vesselType">Type of vessel</label>
        <select id="vesselType" required>
          <option value="" selected>
            Select a vessel type
          </option>
          {vesselTypes.map((vesselType, index) => (
            <option value={index} key={index}>
              {vesselType}
            </option>
          ))}
        </select>

        <label htmlFor="notes">
          Notes
          <textarea rows={3} id="notes" name="notes" />
        </label>

        {submitLoading ? (
          <input
            type="submit"
            aria-busy="true"
            value="Adding Vessel"
            disabled
          />
        ) : (
          <input type="submit" value="Add Vessel" />
        )}
      </form>) : (<div  aria-busy="true"></div>)}
    </div>
  );
}

export default AddVesselPage;
