import { useQuery } from "../../Utils/CustomHooks";
import { collection, doc, where } from "firebase/firestore";
import { getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect } from "react";
import { query } from "firebase/firestore/lite";
import { getDocs } from "firebase/firestore";
import Project from "../../Models/Project";
import { mapDocToProject } from "../../Utils/Mappers";
import { useState } from "react";
import Event from "../../Models/Event";
import NoticeType from "../../Models/Notices";
import EventsTable from "./EventsTable";
import ProjectCard from "../../Components/ProjectCard";
import { decorateProject } from "../../Utils/ProjectDecorator";
import ProjectMeta from "../../Models/ProjectMeta";
import { useGlobalState } from "../../state";

interface ProjectDetailPageProps {}

function ProjectDetailPage(props: ProjectDetailPageProps): JSX.Element {
  const [project, setProject] = useState<Project>();
  const [events, setEvents] = useState<Event[]>([]);
  const [projectMeta, setProjectMeta] = useState<ProjectMeta>();
  const [company, setCompany] = useGlobalState("company");

  const [bunkerEvents, setBunkerEvents] = useState<Event[]>([]);

  let paramQuery = useQuery();
  var queryProjectReference = paramQuery.get("reference");
  var queryProjectId = paramQuery.get("projectId");

  useEffect(() => {
    if (company) {
      if (queryProjectReference) {
        getProjectByReference(queryProjectReference);
      } else if (queryProjectId) {
        getProjectByProjectId(queryProjectId);
      }
    }
  }, [company]);

  useEffect(() => {
    if (project && events.length > 0) {
      setProjectMeta(decorateProject(project, events));
    }
  }, [project, events]);

  async function getProjectByReference(projectReference: string) {
    const docRef = collection(db, "companies", company, "projects");
    const q = query(docRef, where("projectReference", "==", projectReference));
    const querySnapshot = await getDocs(q);
    var retrievedProjects: Project[] = [];
    querySnapshot.forEach((doc) => {
      retrievedProjects.push(mapDocToProject(doc));
    });
    if (retrievedProjects.length > 0) {
      setProject(retrievedProjects[0]);
      getEventsForProject(retrievedProjects[0].id);
    } else {
      console.log("no results");
    }
  }

  async function getProjectByProjectId(projectId: string) {
    const docRef = doc(db, "companies", company, "projects", projectId);
    const querySnapshot = await getDoc(docRef);
    setProject(querySnapshot.data() as Project);
    getEventsForProject(projectId);
  }

  async function getEventsForProject(projectId: string) {
    const docRef = collection(db, "companies", company, "events");
    const q = query(docRef, where("projectId", "==", projectId));
    const querySnapshot = await getDocs(q);
    var retrievedEvents: Event[] = [];
    querySnapshot.forEach((doc) => {
      retrievedEvents.push(doc.data() as Event);
    });
    setEvents(retrievedEvents.filter(filterBunker));
    setBunkerEvents(retrievedEvents.filter((e) => !filterBunker(e)));
  }

  function filterBunker(event: Event) {
    return event.noticeType !== NoticeType.BUNKER_NOTICE;
  }

  return (
    <>
      {project && (
        <>
          <h1>
            Project {project.projectName} - {project.projectReference}
          </h1>
          <ProjectCard project={project} projectMeta={projectMeta} />
          {/* events */}
          <h2>Events</h2>
          <EventsTable events={events} />
          {/* bunker events */}
          <h2>Bunker Events</h2>
          <EventsTable events={bunkerEvents} />
        </>
      )}
    </>
  );
}

export default ProjectDetailPage;
