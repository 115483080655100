export function getEventTimestamp(event: any, machineOffsetFullInMinutes: any) {
  const timestring = event.target.date.value + " " + event.target.time.value;
  const date = timeStringToDate(timestring);
  const machineOffsetMillies = machineOffsetFullInMinutes * 60 * 1000;
  date.setTime(date.getTime() - machineOffsetMillies);
  return date;
}

export function getEventTimestampUTC(
  event: any,
  machineOffsetFullInMinutes: any
) {
  const timestring = event.target.date.value + " " + event.target.time.value;
  const date = timeStringToDate(timestring);
  const UTCOffsetHour = Number(event.target.UTCOffset.value);
  const UTCOffsetMillies = UTCOffsetHour * 60 * 60 * 1000;
  const machineOffsetMillies = machineOffsetFullInMinutes * 60 * 1000;
  date.setTime(date.getTime() - UTCOffsetMillies - machineOffsetMillies);
  return date;
}

export function getTimeStringUTC(eventTimestampUTC: Date) {
  //eventTimestampUTC show in string format yyyy-mm-dd hh:mm
  const yyyy = eventTimestampUTC.getUTCFullYear();
  let mm = eventTimestampUTC.getUTCMonth() + 1; // Months start at 0!
  let dd = eventTimestampUTC.getUTCDate();
  let hh = eventTimestampUTC.getUTCHours();
  let mmin = eventTimestampUTC.getUTCMinutes();

  return (
    yyyy +
    "-" +
    getTwoDigitsNumberString(mm) +
    "-" +
    getTwoDigitsNumberString(dd) +
    " " +
    getTwoDigitsNumberString(hh) +
    ":" +
    getTwoDigitsNumberString(mmin)
  );
}

// below here are functions because this is reusable functionality (not exported, only used in this file)

function getTwoDigitsNumberString(input: number) {
  if (input < 10) {
    return "0" + input.toString();
  }
  return input.toString();
}

//formats a timestring in form yyyy-mm-dd hh:mm:ss to date object
function timeStringToDate(timestring: string): Date {
  const [dateValues, timeValues] = timestring.split(" ");

  const [year, month, day] = dateValues.split("-");
  var [hours, minutes, seconds] = timeValues.split(":");
  if(seconds === undefined){
    seconds ="00";
  }


  return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
}
