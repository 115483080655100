import React, { useEffect, useState, useContext } from "react";
import { db } from "../../firebase";
import { getDocs, collection } from "firebase/firestore";
import ProjectCard from "../../Components/ProjectCard";
import Project from "../../Models/Project";
import { useGlobalState } from "../../state";
import Vessel from "../../Models/Vessel";
import { FieldDefinition } from "../../Models/FieldDefinition";
import { Context } from "../../State/Store";
import { getUserFromState } from "../../State/StateHelper";
import NameId from "../../Models/NameId";

function ProjectsPage() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [company, setCompany] = useGlobalState("company");

  //all needed for filtering
  const [vessels, setVessels] = useState<String[]>([]); //for filtering
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [myVesselsFilterChecked, setMyVesselsFilterChecked] = useState(false);
  const [activeFilterChecked, setActiveFilterChecked] = useState(false);
  const [searchQuery, setsearchQuery] = useState("");

  // @ts-ignore
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    const user = getUserFromState(state);
    if (user && user.hasEditRights) {
      setVessels(
        user.hasEditRights.map((right) => {
          return right.id;
        })
      );
    }
    if (company) {
      getProjectData();
    }
  }, [company]);

  useEffect(() => {
    // updated after implemented filters
    implementFilters();
  }, [searchQuery, activeFilterChecked, myVesselsFilterChecked]);

  async function getProjectData() {
    const docRef = collection(db, "companies", company, "projects");
    const querySnapshot = await getDocs(docRef);
    const projectData: Project[] = [];
    querySnapshot.forEach((doc) => {
      projectData.push(doc.data() as Project);
    });

    setProjects(projectData);
    setFilteredProjects(projectData);
  }

  function handleSwitchMyVessels() {
    console.log("set my vessels");
    setMyVesselsFilterChecked(!myVesselsFilterChecked);
  }

  function handleSwitchActiveFilter() {
    setActiveFilterChecked(!activeFilterChecked);
  }

  function handleUpdateSearchQuery(event: any) {
    const query = event.target.value;
    console.log(query);
    setsearchQuery(query);
  }

  //for now, filter on client
  function implementFilters() {
    var updatedProjectList: Project[] = projects;
    console.log("unfiltered list", updatedProjectList);
    if (myVesselsFilterChecked) {
      updatedProjectList = updatedProjectList.filter((project) => {
        return vessels.includes(project.vesselId);
      });
    }

    if (activeFilterChecked) {
      const now = Date.now();
      updatedProjectList = updatedProjectList.filter((project) => {
        return true; //todo implement
      });
    }
    if (searchQuery.length > 1) {
      console.log("filter robots based on query", searchQuery);
      updatedProjectList = updatedProjectList.filter((project) => {
        return (
          project.projectName +
          project.vesselName +
          project.id +
          project.projectReference
        )
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
    }

    console.log("filtered list", updatedProjectList);

    setFilteredProjects(updatedProjectList);
  }

  return (
    <div>
      <h1>ProjectsPage</h1>
      <p>
        <a href="/dashboard/add-project" role="button">
          add project
        </a>
      </p>
      <fieldset>
        <p>
       <strong> Filter results:</strong>
        {/* <label htmlFor="switch_active_projects">
          <input
            type="checkbox"
            id="switch_active_projects"
            name="switch_active_projects"
            role="switch"
            onChange={handleSwitchActiveFilter}
          />
          Only active projects
        </label> */}
        <label htmlFor="switch_my_projects">
        Only my vessels  
          <input
            type="checkbox"
            id="switch_my_projects"
            name="switch_my_projects"
            role="switch"
            onChange={handleSwitchMyVessels}
          />
        </label>
     
          <textarea
            rows={1}
            id="searchQuery"
            name="searchQuery"
            onInput={handleUpdateSearchQuery}
            placeholder="Search for projects (name, id, reference, vessel)"
          />
</p>
      </fieldset>
      <ul className="list">
        {filteredProjects.map((project) => (
          <li className="list-row">
            <ProjectCard project={project} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProjectsPage;
