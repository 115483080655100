import { TimeRange } from '../Models/TimeRange';


export function secondsToTimerange(seconds: number):TimeRange{
  const secPerDay = 60*60*24;
  const secPerHour  = 60* 60;
  let days =  Math.floor(Math.abs(seconds) / secPerDay); 
  seconds = seconds - (days*secPerDay);
  let hours = Math.floor(Math.abs(seconds) / secPerHour); 
  seconds = seconds - (hours*secPerHour);
  let minutes = Math.floor(seconds / 60 );

  return {days, hours, minutes};
}

export function timeRangeToString(timeRange: TimeRange) {
  if(timeRange.days === undefined){
    return "-";
  }
return timeRange.days + " days, " + timeRange.hours + " hours, " +timeRange.minutes + " minutes";
}