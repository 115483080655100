import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../../firebase";
import User from "../../Models/User";

import { useAuthState } from "react-firebase-hooks/auth";
import { getVesselsString } from "../../Utils/TextsHelpers";
import { useGlobalState } from "../../state";

interface VesselUserPageProps {}

function UserPage(props: VesselUserPageProps): JSX.Element {
  const [user, setUser] = useState<User>();
  const [authUser, loading, error] = useAuthState(auth);
  const [company, setCompany] = useGlobalState("company");

  useEffect(() => {
    if (company) {
      if (authUser !== null && company) {
        getUserById(authUser.uid);
      }
    }
  }, [authUser, company]);

  async function getUserById(userId: string) {
    const docRef = doc(db, "companies", company, "users", userId);
    await getDoc(docRef).then((data) => {
      setUser(data.data() as User);
    });
  }

  return (
    <div>
      <h1>Account</h1>
      <div>
        <strong>User:</strong> {user?.name} <br></br>
        <strong>Type:</strong> {user?.userType}
        <br></br>
        <strong>Status: </strong>
        {user?.accountStatus}
        <br></br>
        <strong>Vessels:</strong> {getVesselsString(user?.hasEditRights)}
      </div>
    </div>
  );
}

export default UserPage;
