// const company = "tuglife";

const firebaseConfig = {
  apiKey: "AIzaSyBEEkJuHtw1-2qahCtEgWlz2dQKQMjrbig",
  authDomain: "tuglife-1d4f3.firebaseapp.com",
  projectId: "tuglife-1d4f3",
  storageBucket: "tuglife-1d4f3.appspot.com",
  messagingSenderId: "500145930051",
  appId: "1:500145930051:web:e84dd2214b286a4f89b9f6",
  measurementId: "G-ZJ86SK8P74"
};

export { firebaseConfig };