import Project from "../Models/Project";
import Event from "../Models/Event";
import NoticeType from "../Models/Notices";
import ProjectStatus from "../Models/ProjectStatus";
import ProjectMeta from "../Models/ProjectMeta";
import { secondsToTimerange } from "./TimeRangeHelpers";

export function decorateProject(project: Project, events: Event[]) {
  events.sort(function (x, y) {
    return x.eventTimestamp.toMillis() - y.eventTimestamp.toMillis();
  });
  const projectStatus = getProjectStatus(events);
  const projectLength = getProjectLength(project, events);
  return { projectStatus, projectLength } as ProjectMeta;
}

function getProjectLength(project: Project, events: Event[]) {
  const onhireEvents = events.filter((e) =>
    ["ONHIRE_REPORT"].includes(e.noticeType)
  );
  const offhireEvents = events.filter((e) =>
    ["OFFHIRE_REPORT"].includes(e.noticeType)
  );

  if (onhireEvents.length === 1 && offhireEvents.length === 1) {
    const startDate = onhireEvents[0].eventTimestamp;
    const endDate = offhireEvents[0].eventTimestamp;
    return secondsToTimerange(Math.abs(endDate.seconds - startDate.seconds));
  }

  return -1;
}

function getProjectStatus(events: Event[]) {
  const noticeEvents = events.filter(filterBunker);
  const lastEvent = noticeEvents[noticeEvents.length - 1];
  if (lastEvent === undefined) {
    return ProjectStatus.INVISIBLE;
  }
  switch (lastEvent.noticeType) {
    case undefined:
      return ProjectStatus.PREPARED;
    case NoticeType.DEPARTURE_REPORT:
      return ProjectStatus.PREPARED;
    case NoticeType.ONHIRE_REPORT:
      return ProjectStatus.ACTIVE;
    case NoticeType.OFFHIRE_REPORT:
      return ProjectStatus.ENDED;
    case NoticeType.ARRIVAL_NOTICE:
      return ProjectStatus.COMPLETED;
  }
}

function filterBunker(event: Event) {
  return !["BUNKER_NOTICE"].includes(event.noticeType);
}
