import IconType from './IconType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./icon.css";

import {
  faPlus,
  faCog,
  faPencilAlt,
  faListUl,
  faAt,
  faUnlockAlt,
  faBars,
  faUser
} from '@fortawesome/free-solid-svg-icons';

interface IconProps {
  iconName: string,
}


function Icon(props: IconProps): JSX.Element {

  function getIcon(iconName: string) {

    switch (iconName) {
      case IconType.PLUS: return <FontAwesomeIcon icon={faPlus} />;
      case IconType.SETTINGS: return <FontAwesomeIcon icon={faCog} />;
      case IconType.EDIT: return <FontAwesomeIcon icon={faPencilAlt} />;
      case IconType.LIST: return <FontAwesomeIcon icon={faListUl} />;
      case IconType.AT: return <FontAwesomeIcon icon={faAt} />;
      case IconType.PASSWORD: return <FontAwesomeIcon icon={faUnlockAlt} />;
      case IconType.BARS: return <FontAwesomeIcon icon={faBars} />;
      case IconType.USER: return <FontAwesomeIcon icon={faUser} />;

      default: return 'notfound';
    }
  }

  return (
    <span className='icon'>{getIcon(props.iconName)}</span>
  )
}

export default Icon;

