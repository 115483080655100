import Event from "../../Models/Event";

interface EventProps {
    event: Event | undefined
}

function EventComponent(props: EventProps): JSX.Element {

  const event = props.event

  return (
    <div>
    <li className="list-row">
      {event &&
      <article className="article-list-row">
         <ul>
          <li>Vessel: {event.vesselName}</li>
          <li>Project: {event.projectName}</li>
          <li>Project Reference: {event.projectReference}</li>
          <li>Notice: {event.noticeName}</li>
          </ul>
        </article>
        }
    </li>
  </div>
  )
};

export default EventComponent;