import { useState } from "react";
import { getTodayDateString } from '../Utils/DateHelpers';

// data on form submit should be retrieved by form component 
//     const timestring = event.target.date.value+ " "+ event.target.time.value;


interface DateFormFieldProps {
  label: string;
}

function DateFormField(props: DateFormFieldProps): JSX.Element {
  const [date, setDate] = useState("");

  return (
    <>
      <label htmlFor="date">{props.label}
      <div className="flex-container ">
        <input type="date" id="date" name="date" className="flex-item input-text" onChange={((e)=>{setDate(e.target.value)})}  value={date} required />
        <button type="button" className="button-today" onClick={()=>{setDate(getTodayDateString())}}>today</button>   
        </div>
      </label>

    </>
  )

}

export default DateFormField;