import "./Menu.css";
import { logout } from "../firebase";

interface MenuProps {
  isAdmin: boolean;
  isBlocked: boolean;
}

function getNavLinks(props: MenuProps) {


  if (props.isBlocked) {
    return;
  }

  if (props.isAdmin) {
    return (
      <>
        {defaultLinks()}
        <a href="/dashboard/admin">Admin</a>
      </>
    );
  }

  return defaultLinks();
}

function defaultLinks() {
  return (
    <>
      <a href="/dashboard/">Main</a>
      <a href="/dashboard/vessels">Vessels</a>
      <a href="/dashboard/projects">Projects</a>
      <a href="/dashboard/add-event">Add event</a>
    </>
  );
}

function Menu(props: MenuProps): JSX.Element {
  return (
    <div className="sidenav">
      {getNavLinks(props)}

      <a href="#" onClick={logout} className="menu_bottom">
        Log out
      </a>
    </div>
  );
}

export default Menu;
