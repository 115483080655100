
function AdminPage() {
  return (
    <div>
      <h1>Adminpage</h1>
      <p>
        Alleen voor de baasjes om eens lekker de boel te
        organiseren!
      </p>
      <ul>
        <li>
          <a href="/dashboard/add-vessel">Add Vessel</a>
        </li>
        <li>
          <a href="/dashboard/user-administration">User Administration</a>
        </li>
        {/* <li> //moved in journey
        <a href="/dashboard/vessel-user">Select vessels for each user</a>

          </li> */}
      </ul>
    </div>
  );
}

export default AdminPage;
