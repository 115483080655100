
interface DashboardProps {
}


function Dashboard(props: DashboardProps): JSX.Element {
  return (
    <div >
      <h1>Dashboard</h1>
      <p>Hier kunnen we wat relevante entiteiten plaatsen, zoals actieve projecten of laatste events oid.</p>
    </div>)
};

export default Dashboard;