import { useEffect, useState } from "react";
import { db, auth } from "../../firebase";
import {
  getDocs,
  getDoc,
  collection,
  serverTimestamp,
  addDoc,
  doc,
} from "firebase/firestore";
import Vessel from "../../Models/Vessel";
import DateFormField from "../../Components/DateFormField";
import ContractType from "../../Models/ContractType";
import { useGlobalState } from "../../state";
import { useAuthState } from "react-firebase-hooks/auth";
import { mapDocToVessel } from "../../Utils/Mappers";

interface AddProjectPageProps {}

function AddProjectPage(props: AddProjectPageProps): JSX.Element {
  const [vessels, setVessels] = useState<Vessel[]>([]);
  const [operators, setOperators] = useState<String[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [globalname, setGlobalname] = useGlobalState("username");
  const [user, loading, error] = useAuthState(auth);
  const [company, setCompany] = useGlobalState("company");

  const contractTypes = Object.values(ContractType);

  useEffect(() => {
    if (company) {
      getVesselData();
      getOperatorsData();
    }
  }, [company]);

  async function getVesselData() {
    const docRef = collection(db, "companies", company, "vessels");
    const querySnapshot = await getDocs(docRef);
    const vesselData: Vessel[] = [];
    querySnapshot.forEach((doc) => {
      vesselData.push(mapDocToVessel(doc));
    });
    setVessels(vesselData);
  }

  async function getOperatorsData() {
    const docRef = doc(db, "companies", company);
    const querySnapshot = await getDoc(docRef);
    const data = querySnapshot.data();
    if (data !== undefined && data.operators !== undefined) {
      setOperators(data.operators);
    }
  }

  async function handleSubmit(event: any) {
    setSubmitLoading(true);
    event.preventDefault();
    const postdata = createPostData(event);
    try {
      const dbrefEvent = collection(db, "companies", company, "projects");
      await addDoc(dbrefEvent, postdata).then((result) => {
        setSubmitLoading(false);
        window.location.href = "/dashboard/project?projectid=" + result.id;
      });
    } catch (err) {
      console.error(err);
      setSubmitLoading(false);
    }
  }

  function createPostData(event: any): any {
    const aVessel = vessels[parseInt(event.target.vessel.value)];
    return {
      projectReference: event.target.reference.value,
      projectName: event.target.name.value,
      vesselName: aVessel.name,
      vesselId: aVessel.id,
      contractType: Object.keys(ContractType)[event.target.contractType.value],
      contractDate: event.target.date.value,
      operator: event.target.operator.value,
      charterer: event.target.charterer.value,
      product: event.target.product.value,
      createdTimestamp: serverTimestamp(),
      createdUserid: user?.uid,
      createdUserName: globalname,
    };
  }

  return (
    <div>
      <h1>Add Project</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="reference">
          Project reference
          <input
            type="text"
            id="reference"
            name="reference"
            className="input-text"
            placeholder="Project reference/identifier"
            required
          />
        </label>

        <label htmlFor="projectname">
          Project name
          <input
            type="text"
            id="name"
            name="name"
            className="input-text"
            placeholder="Project name"
          />
        </label>

        {/* rights: who can make a project for which vessel? only for own vessels? */}
        <label htmlFor="vessel">Vessel</label>
        <select id="vessel" required>
          <option value="" selected>
            Select a vessel
          </option>
          {vessels.map((vessel, index) => (
            <option value={index} key={index}>
              {vessel.name}
            </option>
          ))}
        </select>

        <label htmlFor="contractType">Contract Type</label>
        <select id="contractType" required>
          <option value="" selected>
            Select contract type
          </option>
          {contractTypes.map((contractType, index) => (
            <option value={index} key={index}>
              {contractType}
            </option>
          ))}
        </select>

        <DateFormField label="Contract Date" />

        <label htmlFor="operator">Operator</label>
        <select id="operator" required>
          <option value="" selected>
            Select operator
          </option>
          {operators.map((operator, index) => (
            <option value={index} key={index}>
              {operator}
            </option>
          ))}
        </select>

        <label htmlFor="charterer">
          Charterer
          <input
            type="text"
            id="charterer"
            name="charterer"
            className="input-text"
            placeholder="Charterer"
          />
        </label>

        <label htmlFor="product">
          Tow asset
          <input
            type="text"
            id="product"
            name="product"
            className="input-text"
            placeholder="Tow asset/product"
          />
        </label>

        {submitLoading ? (
          <input
            type="submit"
            aria-busy="true"
            value="Saving project ..."
            disabled
          />
        ) : (
          <input type="submit" value="Create new project" />
        )}
      </form>
    </div>
  );
}

export default AddProjectPage;
